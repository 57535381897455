<template>
  <BaseCard>
    <VueApexCharts
      type="bar"
      :height="options.chart.height"
      :options="options"
      :series="series"
    />
  </BaseCard>
</template>

<script>
import { reactive, toRefs, watch } from "@vue/composition-api";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ReportChart",

  components: {
    VueApexCharts,
  },

  props: {
    series: {
      type: Array,
      default: () => [],
    },

    colors: {
      type: Array,
      default: () => [],
    },

    weeks: {
      type: Array,
    },
  },

  setup(props) {
    const data = reactive({
      options: {
        chart: {
          type: "bar",
          height: 500,
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: "Caricamento...",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: props.weeks,
          title: {
            text: "Settimane",
          },
        },
        yaxis: {
          title: {
            text: "Durata",
          },
        },
        fill: {
          opacity: 1,
        },
        colors: props.colors,
        tooltip: {
          y: {
            formatter: (value) => `${value} ore`,
          },
        },
      },
    });

    watch(
      () => props.series,
      () => {
        data.options = {
          ...data.options,
          ...{ xaxis: { categories: props.weeks } },
          colors: props.colors,
        };
      },
      {
        deep: true,
      }
    );

    return {
      ...toRefs(data),
    };
  },
};
</script>
