const END_POINT = 'reports'
import useCrud from './crud.js'

const { index } = useCrud( END_POINT )

export default {
  index,

}

