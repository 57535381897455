<template>
  <BaseCard>

    <q-toolbar class="q-px-none">

      <q-icon
        name="mdi-filter-variant"
        class="q-mr-sm" />

      <div class="flex-md full-width">

        <BaseDatePicker
          style="width:160px"
          class="q-pb-none q-ml-sm"
          label="Dal"
          dense
          clearable
          clear-icon="close"
          :value="value.from"
          @input="from => setFilter({from})" />

        <BaseDatePicker
          style="width:160px"
          class="q-pb-none q-ml-sm"
          label="Al"
          dense
          clearable
          clear-icon="close"
          :value="value.to"
          @input="to => setFilter({to})" />

      </div>

    </q-toolbar>

  </BaseCard>
</template>

<script>
export default{
  name: 'ReportFilters',

  props: {
    value: {
      type: Object,
    },

  },

  setup(props, {emit}){
    function setFilter( value ){
      emit( 'input', { ...props.value, ...value } )
    }

    return {
      setFilter,
    }
  },

}
</script>

