<template>
  <BaseCard multi-section>

    <q-table
      class="wrapped"
      flat
      square
      binary-state-sort
      hide-bottom
      v-bind="$attrs"
      v-on="$listeners"
      :columns="columns"
      :pagination="pagination"
    >

    </q-table>

  </BaseCard>
</template>

<script>
export default {
  name : 'ReportTableList',

  inheritAttrs: false,

  setup()
  {
    const months = [
     'gennaio',
     'febbraio',
     'marzo',
     'aprile',
     'maggio',
     'giugno',
     'luglio',
     'agosto',
     'settembre',
     'ottobre',
     'novembre',
     'dicembre',
    ]

    const columns = [
      {
        field: 'week',
        name: 'week',
        label: 'Settimana',
        align: 'center',
        style: 'width: 80px',
        sortable: true,
      }, {
        field: 'duration',
        name: 'duration',
        label: 'Durata',
        align: 'center',
        style: 'width: 80px',
        sortable: true,
      },{
        field: 'operator',
        name: 'operator',
        label: 'Operatori',
        align: 'center',
        style: 'width: 80px',
        sortable: true,
      }, {
        field: 'maintenance',
        name: 'maintenance',
        label: 'Schede',
        align: 'center',
        style: 'width: 80px',
        sortable: true,
      }, {
        field: 'vehicle',
        name: 'vehicle',
        label: 'Veicoli',
        align: 'center',
        style: 'width: 80px',
        sortable: true,
      }, {
        name: 'month',
        label: 'Mese',
        sortable: true,
        field: row => months[ parseInt( row.month ) - 1 ],
      },
    ]

    const pagination = {
      sortBy: 'week',
      rowsPerPage: 10000,
    }

    return {
      columns,
      pagination,
    }
  }
}
</script>
