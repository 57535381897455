<template>
  <BaseCard multi-section>
    <q-table
      class="wrapped"
      flat
      square
      binary-state-sort
      hide-bottom
      v-bind="$attrs"
      v-on="$listeners"
      :columns="columns"
      :data="data"
      :pagination="pagination"
    >
      <template v-slot:bottom-row>
        <q-tr class="bg-blue-grey-1">
          <q-th> &nbsp; </q-th>
          <q-th class="text-left"> TOTALI </q-th>
          <template v-for="column in columns">
            <q-th :key="`footer_${column.name}`" v-if="!!column.footer">
              {{ column.footer() }}
            </q-th>
          </template>
        </q-tr>
      </template>
    </q-table>

    <template #header>
      <div class="flex justify-between items-center">
        <h5 class="text-h6 text-black q-mt-lg q-mb-md">Dettaglio ore</h5>

        <div>
          <q-toggle
            label="Mostra giorni"
            v-model="toggleDays"
            color="secondary"
            class="q-mr-md"
          />

          <BaseBtn
            label="Prossime 2 settimane"
            color="pink"
            :disable="!toggleDays"
            @click="setNext2Weeks"
          />
        </div>
      </div>
    </template>
  </BaseCard>
</template>

<script>
import { date } from 'quasar'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'ReportTasksList',

  inheritAttrs: false,

  props: {
    from: String,
    to: String,
    data: Array,
  },

  setup(props, { emit }) {
    const toggleDays = ref(true)

    const round = (num) => {
      return Math.round(num * 100) / 100
    }

    const getTotal = (day) => {
      if (!props.data) return 0

      let total = 0
      props.data.forEach((row) => (total += row.days[day] ?? 0))
      return total
    }

    const getTotalField = (field) => {
      if (!props.data) return 0

      let total = 0
      props.data.forEach((row) => (total += row[field] ?? 0))
      return total
    }

    const setNext2Weeks = () => {
      emit('navigate', {
        from: date.formatDate(new Date(), 'YYYY-MM-DD'),
        to: date.formatDate(
          new Date().setDate(new Date().getDate() + 14),
          'YYYY-MM-DD'
        ),
      })
    }

    const columns = computed(() => {
      //days columns
      const days = []

      if (toggleDays.value) {
        const today = new Date()
        const dateStart = date.extractDate(props.from, 'YYYY-MM-DD')
        const dateEnd = date.extractDate(props.to, 'YYYY-MM-DD')

        while (dateEnd >= dateStart) {
          const day = date.formatDate(dateStart, 'YYYY-MM-DD')

          days.push({
            name: date.formatDate(dateStart, 'DD_MM'),
            label: date.formatDate(dateStart, 'DD/MM'),
            style: `background-color:${
              dateStart > today ? '#ffcdd2' : '#c8e6c9'
            };`,
            align: 'center',
            sortable: true,
            field: (row) => round(row.days[day] ?? 0),
            footer: () => getTotal(day),
          })

          dateStart.setDate(dateStart.getDate() + 1)
        }
      }

      return [
        {
          name: 'color',
          label: '',
          style: (row) => `background-color:${row.color};width:60px;padding:0`,
          footer: false,
        },
        {
          field: 'user',
          name: 'user',
          label: 'Operatore',
          align: 'left',
          sortable: true,
          footer: false,
        },
        ...days,
        {
          name: 'planned',
          label: 'Ore previste',
          align: 'center',
          style: 'width: 80px;',
          sortable: true,
          field: (row) => round(row.planned),
          footer: () => getTotalField('planned'),
        },
        {
          name: 'real',
          label: 'Ore reali',
          align: 'center',
          style: 'width: 80px;',
          sortable: true,
          field: (row) => round(row.real),
          footer: () => getTotalField('real'),
        },
      ]
    })

    const pagination = {
      sortBy: 'user',
      rowsPerPage: 10000,
    }

    return {
      columns,
      pagination,
      toggleDays,
      setNext2Weeks,
    }
  },
}
</script>
