<template>
  <BasePage
    padding
    title="Report operazioni"
    subtitle="analisi durata operazioni sulle schede"
    :breadcrumb="$route.matched"
  >
    <ReportFilters
      class="wrapped"
      :value="filters"
      @input="(newFilters) => (filters = newFilters)"
    />

    <template v-if="data.chart && data.chart.length">
      <ReportChart :colors="colors" :weeks="weeks" :series="data.chart" />
      <ReportTableList :data="data.table" :loading="loading" />
    </template>

    <BaseBanner v-else class="q-mb-md"
      >Non ci sono valori da mostrare nel periodo selezionato</BaseBanner
    >

    <ReportTasksList
      :data="data.tasks"
      :loading="loading"
      :from="filters.from"
      :to="filters.to"
      @navigate="(newFilters) => (filters = newFilters)"
    />

    <BaseInnerLoading :dark="false" :showing="loading" />
  </BasePage>
</template>

<script>
import Reports from '../apis/reports.js'
import ReportChart from '../components/reports/ReportChart.vue'
import ReportFilters from '../components/reports/ReportFilters.vue'
import ReportTableList from '../components/reports/ReportTableList.vue'
import ReportTasksList from '../components/reports/ReportTasksList.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import { date } from 'quasar'
import { computed, toRefs } from '@vue/composition-api'

export default {
  name: 'Reports',

  components: {
    ReportChart,
    ReportFilters,
    ReportTasksList,
    ReportTableList,
  },

  setup(_, ctx) {
    const weeks = computed(() => state.data.weeks || [])
    const colors = computed(() => state.data.colors || [])

    const { loading, state } = useApiCrudTable({
      api: Reports,
      ctx,
      routes: {
        index: 'reports',
      },
      filters: {
        //last 3 months
        from: date.formatDate(
          new Date().setMonth(new Date().getMonth() - 3),
          'YYYY-MM-DD'
        ),
        // until next week
        to: date.formatDate(
          new Date().setDate(new Date().getDate() + 7),
          'YYYY-MM-DD'
        ),
        //reset pagination / sort
        page: null,
        limit: null,
        sort: null,
      },
    })

    return {
      colors,
      loading,
      weeks,
      ...toRefs(state),
    }
  },
}
</script>
